import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInstagram,
  faUntappd,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import './SocialMediaTicker.css';

const SocialMediaTicker = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [socialMediaHandlers] = useState([
    { icon: faInstagram, color: '#e1306c', handler: '@TimeWasterNL' },
    { icon: faUntappd, color: '#ff8e00', handler: '@TimeWasterNL' },
    { icon: faTiktok, color: '#ffffff', handler: '@TimeWasterNL' },
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % socialMediaHandlers.length);
    }, 8000);

    return () => clearInterval(intervalId);
  }, [socialMediaHandlers.length]);

  const { icon, color, handler } = socialMediaHandlers[currentIndex];

  const iconStyle = {
    color
  };

  return (
    <div className="social-media-ticker">
      <FontAwesomeIcon icon={icon} style={iconStyle} className="fade" />
      <span className="fade">{handler}</span>
    </div>
  );
};

export default SocialMediaTicker;
