import { useAppDispatch, useAppSelector } from '../../hooks';
import { MediaPlayerProps, unload } from './MediaPlayerSlice';
import ReactPlayer from 'react-player';
import './MediaPlayer.css';

export default function MediaPlayer() {
    const dispatch = useAppDispatch()
    const props: MediaPlayerProps = useAppSelector((state: any) => state.mediaPlayer)

    return (
        <div className="mediaPlayer">
            <ReactPlayer
                style={{display: props.playing ? "block" : "none"}}
                url={props.file}
                playing={props.playing}
                controls={false}
                height={"100%"}
                width={"100%"}
                onEnded={() => dispatch(unload())} />
        </div>
    );
}