import { useState, useEffect } from 'react';
import './CountdownTimer.css';
import Wave from 'react-wavify';

const CountdownTimer = () => {
  const targetTime = new Date(); // Get the current date and time
  targetTime.setHours(20, 45, 0, 0); // Set the target time to 20:30:00

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());

  function calculateTimeRemaining() {
    const currentTime = new Date();
    const difference = targetTime.getTime() - currentTime.getTime();

    if (difference > 0) {
      const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((difference % (1000 * 60)) / 1000);

      return { hours, minutes, seconds };
    } else {
      // If the target time has passed, return zeros
      return { hours: 0, minutes: 0, seconds: 0 };
    }
  }

  function addPrefixZero(value: number){
    return (value.toString().length === 1 ? "0" : "") + value
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeRemaining(() => calculateTimeRemaining());
    }, 1000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, []);

  if(calculateTimeRemaining().hours + calculateTimeRemaining().minutes + calculateTimeRemaining().seconds > 0) {
    return (
        <div className="countdown-timer">
          <div className="curtains">
            <h3>Starting soon</h3>
            <p>
                {timeRemaining.minutes}:{addPrefixZero(timeRemaining.seconds)}
            </p>

            <div className="waves">
              <Wave fill='#ffffff77'
              options={{
                height: 25,
                amplitude: 20,
                speed: 0.15,
                points: 3
              }}/>
              <Wave fill='#ffffff55'
              options={{
                height: 15,
                amplitude: 15,
                speed: 0.25,
                points: 5
              }}/>
              <Wave fill='#ffffff88'
              options={{
                height: 5,
                amplitude: 15,
                speed: 0.10,
                points: 7
              }}/>
            </div>
          </div>
        </div>
    );
  }else{
    return (
        <div className="countdown-timer"></div>
    );
  }
};

export default CountdownTimer;
