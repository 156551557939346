import { useAppDispatch } from "../hooks";
import { increment } from "../fragment/counter/CounterSlice";

export const KeyboardTrigger = () => {
    const dispatch = useAppDispatch();

    const handleKeyDown = (event: KeyboardEvent) => {
        if(event.key === " "){
            dispatch(increment())
        }
    }

    window.addEventListener('keydown', handleKeyDown);

    return (<div className="keyboard-trigger"></div>)
}