import { useAppSelector } from '../../hooks'
import { CounterProps } from './CounterSlice'
import './Counter.css'

export default function Counter() {
  const props: CounterProps = useAppSelector((state: any) => state.prutsCounter)

  return (
    <div className="counter">
      <span>Shotjee counter: {props.value}</span>
    </div>
  )
}
