import { Note, WebMidi } from 'webmidi';
import { decrement, increment } from '../fragment/counter/CounterSlice';
import { useAppDispatch } from '../hooks';
import { load } from '../fragment/media-player/MediaPlayerSlice';
import { appear } from '../fragment/neon-title/NeonTitleSlice';

const config =
{
    triggers: [
        { note: 36, function: decrement() },
        { note: 37, function: increment() },
        { note: 38, function: appear({ text: "Exclusive", amount: 8, color: "#f46036", duration: 5000, }) },
        { note: 39, function: appear({ text: "TimeWaster", amount: 8, color: "#52ffb8", duration: 5000, }) },

        { note: 60, function: load("./res/jingles/rct_cash.mp3")},
        { note: 61, function: load("./res/jingles/shotjee.mp3")},
        { note: 61, function: appear({ text: "🥃", amount: 8, color: "#ff0000", duration: 4000, }) },
        { note: 62, function: load("./res/jingles/not_enough_jäger.mp3")},
        { note: 64, function: load("./res/jingles/air_horn.mp3") },
        { note: 65, function: load("./res/jingles/grolsch_beugel.wav") },
        { note: 66, function: load("./res/jingles/shotje.mp3") },
        { note: 67, function: load("./res/jingles/je_doet_maar.mp3") },
    ]
}

export const MidiTrigger = () => {
    const dispatch = useAppDispatch()

    WebMidi.enable()
        .then(() => {
            const input = WebMidi.getInputByName("Launchpad");
            const output = WebMidi.getOutputByName("Launchpad");

            input?.addListener('noteon', (e) => {
                output.channels[1].playNote(e.note)
                console.log(e.note.number)
                mapTrigger(e.note, dispatch)
                output.channels[1].stopNote(e.note)
            });
        })
        .catch((err) => console.log("WebMidi could not be enabled", err));

    return (<div className="midi-trigger"></div>)
}

const mapTrigger = (note: Note, dispatch: any) => {

    config.triggers.forEach((trigger: any) => {
        if (trigger.note === note.number) {
            dispatch(trigger.function)
        }
    })
}