import { useAppDispatch, useAppSelector } from '../../hooks';
import { NeonTitleProps, dissappear } from './NeonTitleSlice';
import './NeonTitle.css';

export default function NeonTitle() {
    const dispatch = useAppDispatch()
    const props: NeonTitleProps = useAppSelector((state: any) => state.neonTitle)

    let titles = [...Array(props.amount)].map((_e, i) =>
        <div key={i}
            className="title"
            style={{
                animationDelay: `${(i * 0.4)}s`,
                WebkitTextStrokeColor: props.color
            }}>
            {props.text}
        </div>
    );

    if (props.duration > 0) {
        setTimeout(() => dispatch(dissappear()), props.duration)
    }

    return (
        <div className="neonTitles">
            {titles}
        </div>
    );
}