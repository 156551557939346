import './globals/'
import OverlayCanvas from './fragment/OverlayCanvas';
import Triggers from './triggers/Triggers';

const App = () => {
  return (
    <div className="App">
      <OverlayCanvas />
      <Triggers />
    </div>
  )
}

export default App;
