import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface MediaPlayerProps {
    file: string,
    playing: boolean,
}

const initialState: MediaPlayerProps = {
    file: "",
    playing: false,
}

export const mediaPlayerSlice = createSlice({
    name: 'media-player',
    initialState,
    reducers: {
        load: (state, action: PayloadAction<string>) => {
            state.file = action.payload
            state.playing = true
        },
        unload: () => {
            return initialState
        }
    },
})

export const { load, unload } = mediaPlayerSlice.actions
export default mediaPlayerSlice.reducer