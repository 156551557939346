import { configureStore } from "@reduxjs/toolkit";
import mediaPlayerReducer from './fragment/media-player/MediaPlayerSlice'
import neonTitleReducer from './fragment/neon-title/NeonTitleSlice'
import prutsReducer from './fragment/counter/CounterSlice'

export const store = configureStore({
    reducer: {
        prutsCounter: prutsReducer,
        mediaPlayer: mediaPlayerReducer,
        neonTitle: neonTitleReducer,
    },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch