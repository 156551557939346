import './CinematicBars.css';

const CinematicBars = () => {
    return (
        <div className="cinematic-bars">
            <div className="cinematic-bars__bar cinematic-bars__bar--top" />
            <div className="cinematic-bars__bar cinematic-bars__bar--bottom" />
        </div>
    );
}

export default CinematicBars;