import './HeaderLogo.css';

const HeaderLogo = () => {
    return (
        <div className="header-logo">
            <div className="logo-image">
                <img src="/res/logo.png" />
            </div>
        </div>
    );
}

export default HeaderLogo;