import { PayloadAction, createSlice } from '@reduxjs/toolkit'

export interface NeonTitleProps {
    text: string,
    amount: number,
    color: string,
    duration: number,
}

const initialState: NeonTitleProps = {
    text: "",
    amount: 0,
    color: "transparent",
    duration: 0,
}

export const neonTitleSlice = createSlice({
    name: 'neon-title',
    initialState,
    reducers: {
        appear: (state, action: PayloadAction<NeonTitleProps>) => {
            return action.payload
        },
        dissappear: () => {
            return initialState
        }
    },
})

export const {appear, dissappear} = neonTitleSlice.actions
export default neonTitleSlice.reducer